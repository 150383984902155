<template> 
  <div style=" display: flex;flex-direction: column;min-height: 100vh;">
    <div style="max-height:92vh;overflow-y: scroll;flex: 1;">
      <swiper :options="swiperOption" class='swiper-box'>
        <!--<swiper-slide v-for="v in swipers"><img :src="v.fdcImage" class="swiper-img" style="width:100%;height:1.8rem"></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>-->
        <swiper-slide>
          <!-- <img src="@/assets/lunbo.png" class="swiper-img"  style="width:100%;"> -->
          <img src="@/assets/lunbo_ty.png" class="swiper-img"  style="width:100%;">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="content1">
        <div class="items" v-if="this.type==='2'">
          <div class="item ding">
            <div class="box zz" @click="checkin">
              <div class="name">
                <img src="@/assets/rzdj.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">办理入住</span>
              </div>
            </div>
            <div class="box zz" @click="assistreg">
              <div class="name">
                <img src="@/assets/xzdj.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">协助登记</span>
              </div>
            </div>
            <div class="box zz" @click="checkout">
              <div class="name">
                <img src="@/assets/bltf.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">办理退房</span>
              </div>
            </div>
            <div class="box zz" @click="continuation">
              <div class="name">
                <img src="@/assets/blxz.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">办理续住</span>
              </div>
            </div>          
          </div>
          <div class="item">
            <div class="box zz" @click="adjustment">
              <div class="name">
                <img src="@/assets/rztz.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">入住调整</span>
              </div>
            </div>
            <div class="box zz" @click="checkinquery">
              <div class="name">
                <img src="@/assets/rzcx.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">入住查询</span>
              </div>
            </div>
            <div class="box zz" @click="myintegral">
              <div class="name">
                <img src="@/assets/wdjf.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">我的积分</span>
              </div>
            </div>
            <div class="box zz">
              <div class="name">
                <img src="@/assets/gd.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">更多</span>
              </div>
            </div>          
          </div>
          <div class="item cs">
            <div class="box qq">
              <div class="name">
                <span class="space">{{site_name}}</span>
              </div>
              <div class="name">
                  <span class="space">{{site_code}}</span>
                </div>
              <div class="name">
                <span class="space js">角色：吧员</span>
              </div>
            </div>         
          </div>
        </div>
        <div class="items" v-if="this.type==='1'">
          <div class="item ding">
            <div class="box zz" @click="regionmanage">
              <div class="name">
                <img src="@/assets/qygl.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">区域管理</span>
              </div>
            </div>
            <div class="box zz" @click="barmanage">
              <div class="name">
                <img src="@/assets/btgl.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">吧台管理</span>
              </div>
            </div>
            <div class="box zz" @click="regionbind">
              <div class="name">
                <img src="@/assets/qybd.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">区域绑定</span>
              </div>
            </div>
            <div class="box zz" @click="roommanage">
              <div class="name">
                <img src="@/assets/fjgl.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">房间管理</span>
              </div>
            </div>
                      
          </div>
          <div class="item">
            <div class="box zz" @click="roomstate">
              <div class="name">
                <img src="@/assets/fjzt.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">房间状态</span>
              </div>
            </div>
            <div class="box zz" @click="datastatistics">
              <div class="name">
                <img src="@/assets/sjtj.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">数据统计</span>
              </div>
            </div>
            <div class="box zz" @click="infoquery">
              <div class="name">
                <img src="@/assets/xxcx.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">信息查询</span>
              </div>
            </div>
            <div class="box zz" @click="placeswitch">
              <div class="name">
                <img src="@/assets/csqh.png" class="icon" />
              </div>
              <div class="name">
                <span class="region1">场所切换</span>
              </div>
            </div>          
          </div>
          <div class="item cs">
            <div class="box qq">
              <div class="name">
                <span class="space">{{site_name}}</span>
              </div>
              <div class="name">
                  <span class="space">{{site_code}}</span>
                </div>
              <div class="name">
                <span class="space js">角色：超管</span>
              </div>
            </div>         
          </div>
        </div>
        <div class="items" v-if="this.type==='3'">
          <div class="items" v-if="srole">
            <div class="item ding">
              <div class="box zz" @click="regionmanage">
                <div class="name">
                  <img src="@/assets/qygl.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">区域管理</span>
                </div>
              </div>
              <div class="box zz" @click="barmanage">
                <div class="name">
                  <img src="@/assets/btgl.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">吧台管理</span>
                </div>
              </div>
              <div class="box zz" @click="regionbind">
                <div class="name">
                  <img src="@/assets/qybd.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">区域绑定</span>
                </div>
              </div>
              <div class="box zz" @click="roommanage">
                <div class="name">
                  <img src="@/assets/fjgl.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">房间管理</span>
                </div>
              </div>
                        
            </div>
            <div class="item">
              <div class="box zz" @click="roomstate">
                <div class="name">
                  <img src="@/assets/fjzt.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">房间状态</span>
                </div>
              </div>
              <div class="box zz" @click="datastatistics">
                <div class="name">
                  <img src="@/assets/sjtj.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">数据统计</span>
                </div>
              </div>
              <div class="box zz" @click="infoquery">
                <div class="name">
                  <img src="@/assets/xxcx.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">信息查询</span>
                </div>
              </div>
              <div class="box zz" @click="placeswitch">
                <div class="name">
                  <img src="@/assets/csqh.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">场所切换</span>
                </div>
              </div>          
            </div>
            <div class="item cs">
              <div class="box qq">
                <div class="name">
                  <span class="space">{{site_name}}</span>
                </div>
                <div class="name">
                  <span class="space">{{site_code}}</span>
                </div>
                <div class="name">
                  <span class="space js">角色：超管</span>
                </div>
              </div>         
            </div>
          </div>
          <div class="items" v-if="srole===false">
            <div class="item ding">
              <div class="box zz" @click="checkin">
                <div class="name">
                  <img src="@/assets/rzdj.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">办理入住</span>
                </div>
              </div>
              <div class="box zz" @click="assistreg">
                <div class="name">
                  <img src="@/assets/xzdj.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">协助登记</span>
                </div>
              </div>
              <div class="box zz" @click="checkout">
                <div class="name">
                  <img src="@/assets/bltf.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">办理退房</span>
                </div>
              </div>
              <div class="box zz" @click="continuation">
                <div class="name">
                  <img src="@/assets/blxz.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">办理续住</span>
                </div>
              </div>          
            </div>
            <div class="item">
              <div class="box zz" @click="adjustment">
                <div class="name">
                  <img src="@/assets/rztz.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">入住调整</span>
                </div>
              </div>
              <div class="box zz" @click="checkinquery">
                <div class="name">
                  <img src="@/assets/rzcx.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">入住查询</span>
                </div>
              </div>
              <div class="box zz" @click="myintegral">
                <div class="name">
                  <img src="@/assets/wdjf.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">我的积分</span>
                </div>
              </div>
              <div class="box zz">
                <div class="name">
                  <img src="@/assets/gd.png" class="icon" />
                </div>
                <div class="name">
                  <span class="region1">更多</span>
                </div>
              </div>          
            </div>
            <div class="item cs">
              <div class="box qq">
                <div class="name">
                  <span class="space">{{site_name}}</span>
                </div>
                <div class="name">
                  <span class="space">{{site_code}}</span>
                </div>
                <div class="name">
                  <span class="space js">角色：吧员</span>
                </div>
              </div>         
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<router-view></router-view>
    <BottomNav></BottomNav>-->
    <div class="items" v-if="this.type==='1'">
      <div class="navigation">
        <div class="nav" @click="placename"><img class="navIcon" src="@/assets/sy.png"/>场所</div>
        <div class="nav" @click="datastatistics"><img class="navIcon" src="@/assets/sjtj_1.png"/>统计</div>
        <div class="nav" @click="infoquery"><img class="navIcon" src="@/assets/xxcx_1.png"/>查询</div>
        <div class="nav" @click="switchrole"><img class="navIcon" src="@/assets/qhjs.png"/>吧员</div>
        <div class="nav" @click="myintegral"><img class="navIcon" src="@/assets/wdjf_1.png"/>积分</div>
      </div>
    </div>
    <div class="items" v-if="this.type==='2'">
      <div class="navigation">
        <div class="nav" @click="placename"><img class="navIcon" src="@/assets/sy.png"/>场所</div>
        <div class="nav" @click="checkin"><img class="navIcon" src="@/assets/rz.png"/>入住</div>
        <div class="nav" @click="continuation"><img class="navIcon" src="@/assets/xz1.png"/>续住</div>
        <div class="nav" @click="checkout"><img class="navIcon" src="@/assets/tf.png"/>退房</div>
        <div class="nav" @click="myintegral"><img class="navIcon" src="@/assets/wdjf_1.png"/>积分</div>
      </div>
    </div>
    <div class="items" v-if="this.type==='3'">
      <div class="items" v-if="srole">
        <div class="navigation">
          <div class="nav" @click="placename"><img class="navIcon" src="@/assets/sy.png"/>场所</div>
          <div class="nav" @click="datastatistics"><img class="navIcon" src="@/assets/sjtj_1.png"/>统计</div>
          <div class="nav" @click="infoquery"><img class="navIcon" src="@/assets/xxcx_1.png"/>查询</div>
          <div class="nav" @click="switchrole"><img class="navIcon" src="@/assets/qhjs.png"/>吧员</div>
          <div class="nav" @click="myintegral"><img class="navIcon" src="@/assets/wdjf_1.png"/>积分</div>
        </div>
      </div>
      <div class="items" v-if="srole===false">
        <div class="navigation">
          <div class="nav" @click="placename"><img class="navIcon" src="@/assets/sy.png"/>场所</div>
          <div class="nav" @click="checkin"><img class="navIcon" src="@/assets/rz.png"/>入住</div>
          <div class="nav" @click="checkout"><img class="navIcon" src="@/assets/tf.png"/>退房</div>
          <div class="nav" @click="switchrole"><img class="navIcon" src="@/assets/qhjs.png"/>超管</div>
          <div class="nav" @click="myintegral"><img class="navIcon" src="@/assets/wdjf_1.png"/>积分</div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";
import BottomNav from '@/components/BottomNav';

import { swiper, swiperSlide } from 'vue-awesome-swiper'
require("swiper/css/swiper.css");


export default {
  
  created() {
    let openid = get(this.$route, 'query.openid');
    this.type = get(this.$route, 'query.type');
    this.unionid = get(this.$route, 'query.unionid');

    if (openid !== null && openid !== undefined && openid.length !== 0) {
      window.sessionStorage.setItem('openid', openid);
    } 

    let srole = get(this.$route, 'query.srole');
    if (srole !== null && srole !== undefined) {
      this.srole = srole;
    }    

    this.hrefurl = location.href;
    window.sessionStorage.setItem('hrefurl', this.hrefurl);

    this.query();
    /* = this.getdate();
    this.enddate = this.getdate();
    this.nowdate = this.getdate();*/
  }, 
  /*mounted() {
    this.initCalendar();
     setTimeout(() => {
        this.asyncCount = 5
      }, 1000)
  },*/
  data() {
    return { 
      hrefurl: '',
      type: '3',
      unionid: '',
      srole: true,
      site_name: '',
      site_code: '',
      type_name: '',
      //startdate: '',
      //enddate: '',
      //nowdate: '',
      //dateSpace: '',
      time: new Date(),
      mydate: '',
      items: [],
      swiperOption: {//swiper3
        autoplay: 3000,
        speed: 1000,
      }
    };
  },
  methods: {
    async placename() {
      if(this.type==='2'){
        let { data } = await this.axios.post(
          '/ctid/TldjService/dj_getsitename',
        qs.stringify({}));
        if (data.flag === '0') {
          var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
          var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
          //Toast(testobj.site_name);     
          Dialog({ message: testobj.site_name+"</br>"+testobj.site_code });   
        } else {
          Toast(data.message);
        }
      }else{
        this.$router.replace({path:'/boss/modify_info', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
      }     
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_getsitename',
      qs.stringify({}));
      if (data.flag === '0') {
        //this.site_name = data.data.site_name;   
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
        this.site_name = testobj.site_name; 
        this.site_code = testobj.site_code;         
      }
    },
    switchrole() {
      if(this.type==='3'){
        this.srole =! this.srole
      }else{
        Toast('非吧员不能切换');  
      }     
    },
    checkin() {
      this.$router.replace({path:'/barcounter/menu_room_state', query:{type:this.type,unionid:this.unionid,srole:this.srole}});   
    },
    assistreg() {
      this.$router.replace({path:'/barcounter/assist_register', query:{type:this.type,unionid:this.unionid,srole:this.srole}});          
    },
    checkout() {
      this.$router.replace({path:'/barcounter/check_out', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    continuation() {
      this.$router.replace({path:'/barcounter/continuation_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});   
    },
    adjustment() {
      this.$router.replace({path:'/barcounter/adjust_room_state', query:{type:this.type,unionid:this.unionid,srole:this.srole}});      
    },
    checkinquery() {
      this.$router.replace({path:'/barcounter/check_in_query', query:{type:this.type,unionid:this.unionid,srole:this.srole}});         
    },
    myintegral() {
      Toast('我的积分功能尚未开放');   
    },
    regionmanage() {
      this.$router.replace({path:'/boss/region_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    }, 
    barmanage() {
      this.$router.replace({path:'/boss/bar_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}}); 
    }, 
    roommanage() {
      this.$router.replace({path:'/boss/room_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}}); 
    }, 
    regionbind() {
      this.$router.replace({path:'/boss/bind_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});  
    }, 
    roomstate() {
      this.$router.replace({path:'/boss/room_state', query:{type:this.type,unionid:this.unionid,srole:this.srole}}); 
    }, 
    datastatistics() {
      this.$router.replace({path:'/boss/room_census', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    }, 
    infoquery() {
      this.$router.replace({path:'/boss/check_in_query', query:{type:this.type,unionid:this.unionid,srole:this.srole}});    
    }, 
    placeswitch() {
      this.$router.replace({path:'/boss/place_switch', query:{type:this.type,unionid:this.unionid,srole:this.srole}});  
    }/*,    
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    }/*,   
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    }/*,
    initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 36,
          minDate: this.nowdate,
          maxDate: '2020-05-21',
          onSuccess: (data) => {
            this.dateSpace = data.start_date +'~'+ data.end_date;
            this.startdate = data.start_date;
            this.enddate = data.end_date;
            console.log(this.dateSpace);
          },
          onError: function(msg) {
            console.log(msg)
          }
      });
    }*/
    
  },
  components:{
    //BottomNav,
    swiper,
    swiperSlide

  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
  
}
.content {
  margin-top: 60px;
  max-height: 92vh;
  overflow-y: scroll;
  flex: 1;
}
.content1 {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  background-color:transparent;
  padding: 20px;
  position: relative;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
  height: 80px;
  width: 80px;
  overflow: hidden;
  float: right;
  .icon1 {
    width: 50px;
    height: 50px;
    margin: 8px;
    display: block;

  }
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  .item {
    margin-top: 0px;
    padding: 0px;
    overflow: hidden;
    display: flex;
    &.ding {
      margin-top: 10px;
    }
    &.cs {
      margin-top: 150px;
    }
    &.bj {
      margin-top: 0px;
      background: #021418;
    }
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 25%;
    }
    &.yy {
      width: 15%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 25%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      text-align: center;
      &.tt {
        margin-top: 60px;
      }
      .icon {
        width: 120px;
        height: 120px;
        vertical-align: middle;
      }

      .space {
        font-size: 28px;
        color: rgb(187, 189, 190);     
        &.js {
          font-size: 26px;
        }
      }

      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h3 {
          margin-right: 35px;
        }
      }
      .imgpic {
        display: block;
        width: 320px;  
        height: 203px;  
        float: right;
      }     
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 55px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.tj {
  width: 100%;
  height: 100px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  display: flex;
  line-height: 100px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;  
  cursor: pointer;
  &.primary {
    //background: linear-gradient(90deg, rgba(98, 226, 230, 1) 0%, rgba(18, 129, 132, 1) 100%);  
    background: rgb(30, 66, 114);
    color: #fff;
  }
  
  &.primary0 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(240, 118, 48);
    color: #fff;
    float: right;
  }
  
  &.primary1 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(114, 191, 214);
    color: #fff;
    float: right;
  }
  &.primary2 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(226, 133, 176);
    color: #fff;
    float: right;
  }
  &.primary3 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(59, 164, 211);
    color: #fff;
    float: right;
  }
  &.primary4 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(245, 179, 74);
    color: #fff;
    float: right;
  }
  &.primary5 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);    
    background: rgb(161, 132, 207);
    color: #fff;
    float: right;
  }
  &.primary6 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(181, 204, 80);
    color: #fff;
    float: right;
  }
   &.primary7 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(124, 167, 223);
    color: #fff;
    float: right;
  }
  &.primary8 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(243, 134, 139);
    color: #fff;
  }
  &.primary9 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(100, 120, 212);
    color: #fff;
  }

  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
  &.rr {
    margin-right: 20px;
  }
  .nbx {
    flex: 1;
    overflow: hidden;
    font-size: 26px;   
    color: rgb(255, 255, 255);   
    text-align: center;
    &.h1 {
      margin-left: 30px;
    }
    &.h2 {
      text-align: right; 
      margin-right: 30px;        
    }    
    &.h3 {
      margin-left: 100px; 
    }
  }
}

.navigation{
  display:flex;/*设置五个导航条的内容由列排列变为行排列*/
  height: 8vh ;
  font-size: 0.8rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 999;
}
.nav{
  display: flex;/*这个和下面的 flex-direction属性设置将图片与文字平行排布*/
  flex-direction: column;
  flex: 1;/*设置五个导航条的内容等分*/
  /*margin: 0.5rem 0 0 1rem;*/
  text-align: center;
  line-height: 1.2rem;
  padding-top: 0.5rem;
}
.navIcon{
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 auto;
  display: inline-block;
}


</style>
