<template>
    <div class="navigation">
        <div class="nav"><img class="navIcon" src="@/assets/sy.png"/>首页</div>
        <div class="nav"><img class="navIcon" src="@/assets/rz.png"/>入住</div>
        <div class="nav"><img class="navIcon" src="@/assets/xz1.png"/>续住</div>
        <div class="nav"><img class="navIcon" src="@/assets/tf.png"/>退房</div>
        <div class="nav"><img class="navIcon" src="@/assets/me.png"/>我的</div>
    </div>
</template>

<script>
  export default {}
</script>

<style>
  .navigation{
    display:flex;/*设置五个导航条的内容由列排列变为行排列*/
    height: 8vh ;
    font-size: 0.7rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 999;
  }
  .nav{
    display: flex;/*这个和下面的 flex-direction属性设置将图片与文字平行排布*/
    flex-direction: column;
    flex: 1;/*设置五个导航条的内容等分*/
    /*margin: 0.5rem 0 0 1rem;*/
    text-align: center;
    line-height: 1.2rem;
    padding-top: 0.5rem;
  }
  .navIcon{
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 auto;
    display: inline-block;
  }

</style>
